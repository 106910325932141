import React from "react";
import Script from "next/script";
import { eventBus } from "../EventBus";
function PlausibleAnalyticsInit(_a) {
    var config = _a.config;
    var onScriptLoad = function () {
        eventBus.flush("plausible");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Script, { defer: true, "data-domain": config.domain, src: "https://plausible.io/js/script.js", onLoad: onScriptLoad }),
        React.createElement(Script, { id: "plausible-analytics" }, "window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }")));
}
export default PlausibleAnalyticsInit;
