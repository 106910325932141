"use client";
import Script from "next/script";
import React, { useContext } from "react";
import { ConsentContext } from "../context/ConsentContextProvider";
function Init(_a) {
    var _b, _c;
    var config = _a.config;
    var consent = useContext(ConsentContext).consent;
    var measurementIds = (_c = (_b = config.measurementIdString) === null || _b === void 0 ? void 0 : _b.split(",")) !== null && _c !== void 0 ? _c : [];
    if (measurementIds.length === 0) {
        return null;
    }
    if (!(consent.accepted && consent.analytics)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Script, { src: "https://www.googletagmanager.com/gtag/js?id=".concat(measurementIds[0], "\"") }),
        React.createElement(Script, { id: "adwords-data-layer" }, "\n        window.dataLayer = window.dataLayer || [];\n        function gtag(){dataLayer.push(arguments);}\n        gtag('js', new Date());\n      ",
            measurementIds
                .map(function (measurementId) {
                return "gtag('config', '".concat(measurementId, "');");
            })
                .join(""))));
}
export default Init;
