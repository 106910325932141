"use client";
import React from "react";
import BitterbalInit from "./Bitterbal/Init";
import AdwordsInit from "./Adwords/Init";
import GaInit from "./GA4/Init";
import PlausibleAnalyticsInit from "./PlausibleAnalytics/Init";
import VeroInit from "./Vero/Init";
import SnowplowInit from "./Snowplow/Init";
export default function Init(_a) {
    var snowplowConfig = _a.snowplowConfig, gaConfig = _a.gaConfig, adwordsConfig = _a.adwordsConfig, veroConfig = _a.veroConfig, plausibleConfig = _a.plausibleConfig, bitterbalConfig = _a.bitterbalConfig;
    if (typeof window === "undefined") {
        return;
    }
    SnowplowInit(snowplowConfig);
    BitterbalInit(bitterbalConfig);
    return (React.createElement(React.Fragment, null,
        React.createElement(GaInit, { config: gaConfig }),
        React.createElement(AdwordsInit, { config: adwordsConfig }),
        React.createElement(VeroInit, { config: veroConfig }),
        React.createElement(PlausibleAnalyticsInit, { config: plausibleConfig })));
}
