export type OptionType = {
  id: string;
  name: string;
  description: string;
  editable: boolean;
  type: string;
};

const options: OptionType[] = [
  {
    id: 'functional',
    name: 'Functional',
    description:
      "Allows you to make use of our services and to make sure the website functions properly. You can't use the platform without these cookies.",
    editable: false,
    type: 'cookie-consent',
  },
  {
    id: 'analytics',
    name: 'Analytics',
    description:
      'Used to analyse and improve the usability and performance of the platform. These cookies are always active.',
    editable: true,
    type: 'cookie-consent',
  },
  {
    id: 'personalised_ads',
    name: 'Personalised ads',
    description:
      'Receive personalised advertisements based on your preferences and interests.',
    editable: true,
    type: 'cookie-consent',
  },
  {
    id: 'advertisement',
    name: 'Advertising data sharing',
    description:
      'Enable data sharing to allow our partners to provide you with relevant advertisements.',
    editable: true,
    type: 'cookie-consent',
  },
  {
    id: 'social',
    name: 'Social',
    description:
      'Cookies to allow you to login and share through social platforms.',
    editable: true,
    type: 'cookie-consent',
  },
  {
    id: 'location',
    name: 'Location',
    description:
      'Used to determine your location and show you relevant content.',
    editable: true,
    type: 'permission',
  },
];

export default options;
