"use client";
import Script from "next/script";
import React, { useContext } from "react";
import { eventBus } from "../EventBus";
import { ConsentContext } from "../context/ConsentContextProvider";
export default function VeroInit(_a) {
    var config = _a.config;
    var consent = useContext(ConsentContext).consent;
    var onScriptLoad = function () {
        eventBus.flush("vero");
    };
    if (!consent.accepted || !consent.analytics) {
        return null;
    }
    return (React.createElement(Script, { id: "vero-tracking", onLoad: onScriptLoad }, "\n      var _veroq = _veroq || [];\n      _veroq.push(['init', { api_key: '".concat(config.apiKey, "'}]);\n      (function() {var ve = document.createElement('script'); ve.type = 'text/javascript'; ve.async = true; ve.src = 'https://d3qxef4rp70elm.cloudfront.net/m.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ve, s);})();\n    ")));
}
