"use client";
import Cookies from "js-cookie";
import { useState } from "react";
import { CONSENT_COOKIES_KEYS } from "../constants";
import { eventBus } from "../EventBus";
var DEFAULT_COOKIE_OPTIONS = {
    expires: 9999,
};
var INITIAL_CONSENT = {
    social: Cookies.get(CONSENT_COOKIES_KEYS.SOCIAL)
        ? Cookies.get(CONSENT_COOKIES_KEYS.SOCIAL) === "true"
        : true,
    advertisement: Cookies.get(CONSENT_COOKIES_KEYS.ADVERTISEMENT)
        ? Cookies.get(CONSENT_COOKIES_KEYS.ADVERTISEMENT) === "true"
        : true,
    personalised_ads: Cookies.get(CONSENT_COOKIES_KEYS.PERSONALISED_ADS)
        ? Cookies.get(CONSENT_COOKIES_KEYS.PERSONALISED_ADS) === "true"
        : true,
    accepted: Cookies.get(CONSENT_COOKIES_KEYS.CONSENT_SAVED) === "true",
    analytics: Cookies.get(CONSENT_COOKIES_KEYS.ANALYTICS)
        ? Cookies.get(CONSENT_COOKIES_KEYS.ANALYTICS) === "true"
        : true,
    location: Cookies.get(CONSENT_COOKIES_KEYS.LOCATION)
        ? Cookies.get(CONSENT_COOKIES_KEYS.LOCATION) === "true"
        : false,
};
function useCookieConsent() {
    var _a = useState(INITIAL_CONSENT), consent = _a[0], setConsent = _a[1];
    var onSaveConsent = function (_a) {
        var advertisement = _a.advertisement, social = _a.social, analytics = _a.analytics, location = _a.location, personalised_ads = _a.personalised_ads;
        Cookies.set(CONSENT_COOKIES_KEYS.ADVERTISEMENT, String(advertisement), DEFAULT_COOKIE_OPTIONS);
        Cookies.set(CONSENT_COOKIES_KEYS.PERSONALISED_ADS, String(personalised_ads), DEFAULT_COOKIE_OPTIONS);
        Cookies.set(CONSENT_COOKIES_KEYS.ANALYTICS, String(analytics), DEFAULT_COOKIE_OPTIONS);
        Cookies.set(CONSENT_COOKIES_KEYS.SOCIAL, String(social), DEFAULT_COOKIE_OPTIONS);
        if (location !== undefined) {
            Cookies.set(CONSENT_COOKIES_KEYS.LOCATION, String(location), DEFAULT_COOKIE_OPTIONS);
        }
        Cookies.set(CONSENT_COOKIES_KEYS.CONSENT_SAVED, "true", advertisement && social && analytics && personalised_ads
            ? DEFAULT_COOKIE_OPTIONS
            : { expires: 7 });
        eventBus.dispatch({
            name: "consent",
            params: { analytics: analytics, advertisement: advertisement, personalised_ads: personalised_ads },
        });
        eventBus.flush();
        return setConsent({
            advertisement: advertisement,
            personalised_ads: personalised_ads,
            social: social,
            analytics: analytics,
            accepted: true,
            location: location,
        });
    };
    var isConsentSet = function (id) {
        var _a;
        return (_a = consent === null || consent === void 0 ? void 0 : consent[id]) !== null && _a !== void 0 ? _a : false;
    };
    return {
        consent: consent,
        actions: {
            onSaveConsent: onSaveConsent,
            isConsentSet: isConsentSet,
        },
    };
}
export default useCookieConsent;
