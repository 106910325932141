"use client";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { eventBus } from "./EventBus";
function PageViewTracker() {
    var _a;
    // const { data: sessionData, status: sessionStatus } = useSession();
    var sessionData = {};
    var sessionStatus = "loading";
    var _b = (_a = sessionData === null || sessionData === void 0 ? void 0 : sessionData.user) !== null && _a !== void 0 ? _a : {}, id = _b.id, email = _b.email;
    var pathname = usePathname();
    var searchParams = useSearchParams();
    // Check for navigation events as per Vercel's own recommendation
    // https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
    useEffect(function () {
        // To avoid duplicate page view events, we only send the event if the session is not loading
        if (sessionStatus === "loading")
            return;
        var pageLocation = searchParams
            ? "".concat(window.location.origin).concat(pathname, "?").concat(searchParams)
            : "".concat(window.location.origin).concat(pathname);
        eventBus.dispatch({
            name: "page_view",
            params: { page_location: pageLocation, _userId: id, _userEmail: email },
        });
    }, [id, email, sessionStatus, pathname, searchParams]);
    return null;
}
export default PageViewTracker;
