'use client';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FormEvent, useContext, useEffect, useState } from 'react';

import getPlatform from 'common-toolbox/src/helpers/utils/getPlatform';

import { ConsentContext } from '@withlocals/analytics/context/ConsentContextProvider';

import ConsentOptionsList from './ConsentOptionsList';
import CSS from './CookieConsent.module.scss';

const isServer = getPlatform() === 'server';

function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false);
  const [showOptions, setOnShowOptions] = useState(false);
  const { consent, actions } = useContext(ConsentContext);

  const onShowOptions = () => setOnShowOptions(true);

  useEffect(() => {
    if (!consent.accepted && !isServer) {
      setShowConsent(true);
    }
  }, [consent.accepted]);

  const onConsent = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const data = new FormData(event.currentTarget);
    const consent = {
      advertisement: data.get('advertisement') === 'on',
      personalised_ads: data.get('personalised_ads') === 'on',
      social: data.get('social') === 'on',
      location: data.get('location') === 'on',
      analytics: data.get('analytics') === 'on',
    };

    actions.onSaveConsent(consent);
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <aside className={CSS.Container}>
      <motion.div
        className={CSS.CookieConsent}
        initial={{ y: 16, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeOut', duration: 0.2 }}
      >
        <h2 className={CSS.Title}>Cookie Consent</h2>
        {!showOptions && (
          <p className={CSS.Paragraph}>
            We use cookies to make sure we give you the best user experience on
            our platform! Furthermore we use cookies for analytics & marketing
            purposes.
          </p>
        )}
        <form onSubmit={onConsent}>
          <ConsentOptionsList onShowOptions={onShowOptions} />
          <button
            type="submit"
            className={clsx(CSS.AcceptButton, {
              [CSS.FullWidthButton]: showOptions,
            })}
          >
            {showOptions ? 'Accept & continue' : 'Accept cookies'}
          </button>
        </form>
      </motion.div>
    </aside>
  );
}

export default CookieConsent;
