import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"../../packages/common-toolbox/src/styles/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Graphik-Bold-Web.woff2\",\"weight\":\"700\"},{\"path\":\"./fonts/Graphik-Semibold-Web.woff2\",\"weight\":\"600\"},{\"path\":\"./fonts/Graphik-Medium-Web.woff2\",\"weight\":\"500\"},{\"path\":\"./fonts/Graphik-Regular-Web.woff2\",\"weight\":\"400\"}],\"fallback\":[\"verdana\",\"system-ui\",\"arial\"],\"variable\":\"--font-family\"}],\"variableName\":\"Graphik\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/common-toolbox/src/components/CookieConsent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/common-toolbox/src/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/common-toolbox/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ConsentContextProvider"] */ "/app/packages/withlocals-analytics/dist/context/ConsentContextProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/withlocals-analytics/dist/Init.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/withlocals-analytics/dist/PageViewTracker.js");
