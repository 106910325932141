"use client";
import React, { createContext } from "react";
import useCookieConsent from "../hooks/useCookieConsent";
export var ConsentContext = createContext({
    consent: {
        advertisement: false,
        social: false,
        analytics: false,
        personalised_ads: false,
        location: false,
        accepted: false,
    },
    actions: {
        onSaveConsent: function () { },
        isConsentSet: function () { return false; },
    },
});
export function ConsentContextProvider(_a) {
    var children = _a.children;
    var value = useCookieConsent();
    return (React.createElement(ConsentContext.Provider, { value: value }, children));
}
