"use client";
import { newTracker, enableActivityTracking } from "@snowplow/browser-tracker";
function SnowplowInit(_a) {
    var snowplowCollectorUrl = _a.snowplowCollectorUrl, environment = _a.environment;
    console.log("SnowplowInit initialize env", snowplowCollectorUrl, environment);
    newTracker("sp", snowplowCollectorUrl, {
        appId: "withlocals-storefront-".concat(environment),
    });
    enableActivityTracking({
        minimumVisitLength: 30,
        heartbeatDelay: 10,
    });
}
export default SnowplowInit;
