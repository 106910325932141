import CSS from './ConsentOption.module.scss';

interface ConsentOptionProps {
  id: string;
  name: string;
  description: string;
  editable: boolean;
  checked?: boolean;
}

const getDefaultChecked = (
  isChecked: boolean | undefined,
  isEditable: boolean,
) => {
  if (isChecked !== undefined) {
    return isChecked;
  }
  if (!isEditable) {
    return true;
  }
  return true;
};

function ConsentOption({
  id,
  name,
  description,
  editable,
  checked,
}: ConsentOptionProps) {
  const defaultChecked = getDefaultChecked(checked, editable);

  return (
    <li className={CSS.ConsentOption}>
      <label htmlFor={id} className={CSS.Label}>
        <strong>{name}</strong>
        {!editable && ' (required)'}
      </label>
      <label className={CSS.SwitchButton}>
        <input
          type="checkbox"
          id={id}
          name={id}
          defaultChecked={defaultChecked}
          readOnly={!editable}
          disabled={!editable}
          className={CSS.Checkbox}
        />
        <span className={CSS.Slider} />
      </label>
      <p className={CSS.Description}>{description}</p>
    </li>
  );
}

export default ConsentOption;
