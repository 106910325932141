'use client';

import clsx from 'clsx';
import { useState } from 'react';

import options, { OptionType } from 'common-toolbox/src/constants/consent';

import ConsentOption from './ConsentOption';
import CSS from './ConsentOptionsList.module.scss';

interface ConsentOptionsListProps {
  onShowOptions: () => void;
}

function ConsentOptionsList({ onShowOptions }: ConsentOptionsListProps) {
  const [showOptions, setShowOptions] = useState(false);
  const onToggleOptions = () => {
    setShowOptions(!showOptions);
    onShowOptions();
  };
  const consentOptions = options.filter(
    ({ type }) => type === 'cookie-consent',
  );
  return (
    <>
      <ul className={clsx(CSS.List, { [CSS.HiddenList]: !showOptions })}>
        {consentOptions.map((option: OptionType) => (
          <ConsentOption
            key={option.id}
            id={option.id}
            name={option.name}
            description={option.description}
            editable={option.editable}
          />
        ))}
      </ul>
      {!showOptions && (
        <button
          type="button"
          onClick={onToggleOptions}
          className={CSS.ChangeButton}
        >
          Change settings
        </button>
      )}
    </>
  );
}

export default ConsentOptionsList;
