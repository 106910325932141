"use client";
import Script from "next/script";
import React, { useContext } from "react";
import { eventBus } from "../EventBus";
import { ConsentContext } from "../context/ConsentContextProvider";
var REGIONS_DENY_DEFAULT = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
    "US-CA",
];
function Init(_a) {
    var config = _a.config;
    var consent = useContext(ConsentContext).consent;
    var onScriptLoad = function () {
        if (consent.accepted) {
            eventBus.dispatch({
                name: "consent",
                params: {
                    advertisement: consent.advertisement,
                    analytics: consent.analytics,
                    personalised_ads: consent.personalised_ads,
                },
            });
        }
        eventBus.flush("ga4");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Script, { src: "https://www.googletagmanager.com/gtag/js?id=".concat(config.measurementId), onLoad: onScriptLoad }),
        React.createElement(Script, { id: "ga-data-layer" }, "\n        window.dataLayer = window.dataLayer || [];\n        function gtag(){ console.log(\"sending to dataLayer\", arguments); dataLayer.push(arguments);}\n        gtag('js', new Date());\n        gtag('config', '".concat(config.measurementId, "');\n        gtag('consent', 'default', {\n          'analytics_storage': 'granted',\n          'ad_storage': 'granted',\n          'ad_user_data': 'granted',\n          'ad_personalization': 'granted',\n        });\n        gtag('consent', 'default', {\n          'analytics_storage': 'denied',\n          'ad_storage': 'denied',\n          'ad_user_data': 'denied',\n          'ad_personalization': 'denied',\n          'region': ").concat(JSON.stringify(REGIONS_DENY_DEFAULT), "\n        });\n      "))));
}
export default Init;
